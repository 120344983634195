class Reminder {
    constructor() {
        this.formReminder = "#form-reminder";
        this.modalReminder = "#modalReminderPulse";
        this.dataToReminder = {};

        this.events();
    }

    events() {
        let self = this;
        $(this.formReminder).on("submit", function(e) {
            e.preventDefault();
            self.sendForm();
        });
    }

    sendForm() {
        let self = this;
        self.dataToReminder = {
            pulse_id: $('input[name="pulse_id"]').val(),
            poll_links: $('input[name="poll_links"]')
                .val()
                .split(","),
            date: $('input[name="date"]').val(),
            spanish_subject: $('input[name="subject"]').val(),
            spanish_message: $('textarea[name="message"]').val()
        };

        $.ajax({
            type: "POST",
            url: `/happindex/api/reminders`,
            data: self.dataToReminder,
            beforeSend: function() {},
            success: function(data) {
                $(self.modalReminder).modal("hide");
            },
            error: function(error) {
                $(self.modalReminder).modal("hide");
                console.error(error);
            }
        });
    }
}

if ($("#form-reminder").length) {
    const reminder = new Reminder();
}
