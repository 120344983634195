class SanitizeJS {
    constructor(str = "", nodes = []) {
        this.str = str;
        this.nodes = nodes;
    }

    stringToHTML() {
        const parser = new DOMParser();
        const doc = parser.parseFromString(this.str, "text/html");

        if (doc.body) return doc.body;

        return document.createElement("body");
    }

    removeScripts(html) {
        const scripts = html.querySelectorAll("script");

        for (let script of scripts) {
            script.remove();
        }
    }

    isPossiblyDangerous(name, value) {
        const val = value.replace(/\s+/g, "").toLowerCase();

        if (name.startsWith("on")) return true;
        if (["src", "href", "xlink:href"].includes(name)) {
            if (val.includes("javascript:")) {
                return true;
            } else if (val.includes("data:text/html")) {
                return true;
            }
        }

        return false;
    }

    removeAttributes(elem) {
        const atts = elem.attributes;

        for (let att of atts) {
            if (!this.isPossiblyDangerous(att.name, att.value)) continue;

            elem.removeAttribute(att.name);
        }
    }

    clean(html) {
        const nodes = html.children;

        for (let node of nodes) {
            this.removeAttributes(node);
            this.clean(node);
        }
    }

    sanitize(str = "", nodes = []) {
        this.str = str;
        this.nodes = nodes;

        const html = this.stringToHTML();

        this.removeScripts(html);
        this.clean(html);

        if (nodes.length !== 0) return html.childNodes;

        return html.innerHTML;
    }

    sanitizeObject(object){
        for (let property in object) {
            if (object.hasOwnProperty(property)) {
                if(typeof object[property] !== "object"){
                    object[property] = this.sanitize(object[property]);
                }else{
                    object[property] = this.sanitizeObject(object[property]);
                }
            }
        }

        return object;
    }

}

export default SanitizeJS;
