class SanitizeCustom {
    escape(data) {
        let type = typeof data;

        if (type === "undefined")
            return undefined;

        if ( type !== 'boolean') {
            data = validator.escape(data);
        }

        return data;
    }

    escapeHtml(data) {
        return data;
        //return typeof data === 'undefined' ? undefined : validator.escape( data );
    }

    escapeArray(data) {
        if (Array.isArray(data)) {
            return data.map(item => {
                return validator.escape(item);
            });
        }

        return data;
    }

    escapeObject(data) {
        return data;
    }

}

export default SanitizeCustom;
