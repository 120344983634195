(function() {
    $.modal = function(options) {
        options = $.extend(
            {
                modalId: undefined
            },
            options
        );

        $.modal.open = function() {
            openModal(options.modalId);
        };
        $.modal.close = function() {
            closeModal(options.modalId);
        };

        function openModal(modalId) {
            $(modalId).removeClass("tw-hidden").addClass("tw-flex");
        }

        function closeModal(modalId) {
            $(modalId)
                .removeClass("tw-flex")
                .addClass("tw-hidden");
        }

        $(".overlay-modal").on("click", function(e) {
            e.stopPropagation();
            if (e.target == document.querySelector(".overlay-modal")) {
                closeModal(options.modalId);
            }
        });

        $(".btn-close-modal").on("click", function(e) {
            e.stopPropagation();
            closeModal(options.modalId);
        });
    };
})();
