import SanitizeJS from "./sanitize";

(function () {
    let toastCount = 0;
    $.toastMaz = function (options) {
        options = $.extend(
            {
                text: "",
                textAction: "",
                icon: "fa-solid fa-trash-can",
                showCloseButton: true,
                position: "top-right",
                timeOut: 3500,
                reload: false,
                onclick: undefined,
                onCloseClick: undefined,
            },
            options
        );
        toastCount++;
        let toastId = "toma-" + toastCount;

        let html = "";

        const sanitize = new SanitizeJS();

        if ($(".toastmaz-tr-0").length === 0) {
            html =
                '<div class="toastmaz-tr-0 tw-fixed tw-z-50 tw-mt-24 md:tw-mt-32 tw-top-0 tw-right-0"></div>';
            $("body").append(html);
        }
        html = "";
        html += `
        <div id="${toastId}" class="mas-toast tw-mb-2 tw-p-4 tw-bg-maz-teal-500 tw-rounded-tl-2xl tw-rounded-bl-2xl tw-transition-all tw-duration-500 tw-ease-in-out" style="translate: 500px;" role="alert">
            <div class="tw-flex tw-items-center tw-gap-4">
                <div class="tw-w-10 tw-h-10 md:tw-w-14 md:tw-h-14 tw-bg-white tw-rounded-lg tw-flex tw-justify-center tw-items-center">
                    <i class="${options.icon} tw-text-lg md:tw-text-2xl tw-text-maz-teal-500"></i>
                </div>
                <p class="tw-text-sm md:tw-text-base tw-text-black tw-mb-0">${options.text}</p>
        `;
        if (options.textAction !== "") {
            html += `<a href="#" class="tw-mb-0 tw-text-black tw-font-medium tw-text-sm md:tw-text-base hover:tw-no-underline hover:tw-text-black">${options.textAction}</a>`;
        }
        if (options.showCloseButton) {
            html += `<a href="#" class="tw-text-lg tw-text-black btn-close-toast"><i class="fa-solid fa-xmark hover:tw-text-black hover:tw-no-underline"></i></a>`;
        }
        html += `
            </div>
        </div>`;
        $(".toastmaz-tr-0").append(sanitize.sanitize(html));

        setTimeout(() => {
            animateIn();
        }, 50);

        setTimeout(() => {
            animateOut($("#" + toastId));
        }, options.timeOut);

        if(options.reload) {
            setTimeout(() => {
                window.location.reload();
            }, options.timeOut + 50);
        }

        function animateIn() {
            let toastMaz = $("#" + toastId);
            toastMaz.css("translate", "0px");
        }

        function animateOut(toastMaz) {
            toastMaz.css("translate", "500px");
            toastMaz.fadeOut("fast", () => {
                toastMaz.remove();
            });
        }

        $(".btn-close-toast").on("click", (evt) => {
            let toastElement = $(evt.target).parent().parent().parent();
            animateOut(toastElement);
        });
    };
})();
