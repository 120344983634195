require("./bootstrap");
require("./jqueryValidateSpanish");
require("./modal");
require("./toast");
require("./formSelect");
require("./samples");
require("./temporalBanner");
require("./pulse");
require("./pulse-menu");
require("./reminder");

