class FormSelector {
    constructor() {
        this.checkBoxOpc = $(".formSelectOption__checkbox");
        this.checkboxAll = $(".formSelectOption__checkbox--all");
        this.formGroupSelectSelect = $(".formGroupSelect__select");
    }

    toggleViewOptions(element) {
        let parent = $(element).parent();
        if (parent.find(".formGroupSelect__options").hasClass("d-none")) {
            this.showOptions(parent);
        } else {
            this.hideOptions(parent);
        }
    }

    showOptions(element) {
        $(element)
            .find(".formGroupSelect__options")
            .removeClass("d-none");
        $(element)
            .find(".formGroupSelectIcon__close")
            .removeClass("d-none");
        this.addBgToFormSelect(element);
    }

    hideOptions(element) {
        $(element)
            .find(".formGroupSelect__options")
            .addClass("d-none");
        $(element)
            .find(".formGroupSelectIcon__close")
            .addClass("d-none");
        this.removeBgToFormSelect(element);
    }

    addBgToFormSelect(element) {
        $(element)
            .find(".formGroupSelect__select")
            .removeClass("formGroupSelect__select--white")
            .addClass("formGroupSelect__select--blue");
    }

    removeBgToFormSelect(element) {
        $(element)
            .find(".formGroupSelect__select")
            .removeClass("formGroupSelect__select--blue")
            .addClass("formGroupSelect__select--white");
    }

    toggleSelectAll(element) {
        let parentElement = $(element)
            .parent()
            .parent()
            .parent();
        let checkBoxes = parentElement.find("input");
        if ($(element).is(":checked")) {
            $(checkBoxes).each(function() {
                $(this).prop("checked", true).trigger("change");
            });
        } else {
            $(checkBoxes).each(function() {
                $(this).prop("checked", false).trigger("change");
            });
        }
    }

    validateCheckCheckBoxOpc(element) {
        if ($(element).not(":checked").length) {
            this.unCheckCheckBoxAll(element);
        }
    }

    unCheckCheckBoxAll(element) {
        let parentElement = $(element)
        .parent()
        .parent()
        .parent()
        .parent();
        let checkboxAll = $(parentElement).find(
            ".formSelectOption__checkbox--all"
        );
        $(checkboxAll).prop("checked", false);
    }
}

if ($(".formGroupSelect").length) {
    let formSelector = new FormSelector();

    formSelector.formGroupSelectSelect.on("click", function() {
        formSelector.toggleViewOptions($(this));
    });

    formSelector.checkboxAll.on("click", function() {
        formSelector.toggleSelectAll($(this));
    });

    formSelector.checkBoxOpc.on("click", function() {
        formSelector.validateCheckCheckBoxOpc($(this));
    });
}
