var lS = window.localStorage;

if (lS.getItem("showBanner") == null) lS.setItem("showBanner", "show");
if (lS.getItem("showHIBanner") == null) lS.setItem("showHIBanner", "show");

$(() => {
    if (lS.getItem("showBanner")) $("#b22-banner").show();
    else $("#b22-banner").hide();
    if (lS.getItem("showHIBanner")) $("#b22-hi-banner").show();
    else $("#b22-hi-banner").hide();

    $("#btn-close").on("click", function() {
        lS.setItem("showBanner", "");
        $("#b22-banner").hide();
    });
    $("#btn-hi-close").on("click", function() {
        lS.setItem("showHIBanner", "");
        $("#b22-hi-banner").hide();
    });
});
