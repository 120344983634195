const message = { 
    en: {
        required: "This field is required.",
        remote: "Please fill out this field.",
        email: "Please enter a valid email address.",
        url: "Please enter a valid URL.",
        date: "Please enter a valid date.",
        dateISO: "Please enter a valid (ISO) date.",
        number: "Please enter a valid integer.",
        digits: "Please write only digits.",
        creditcard: "Please enter a valid card number.",
        equalTo: "Please type the same value again.",
        accept: "Please enter a value with an accepted extension.",
        maxlength: jQuery.validator.format(
            "Please do not type more than {0} characters."
        ),
        minlength: jQuery.validator.format(
            "Please do not write less than {0} characters."
        ),
        rangelength: jQuery.validator.format(
            "Please enter a value between {0} and {1} characters."
        ),
        range: jQuery.validator.format(
            "Please enter a value between {0} and {1}."
        ),
        max: jQuery.validator.format(
            "Please enter a value less than or equal to {0}."
        ),
        min: jQuery.validator.format(
            "Please enter a value greater than or equal to {0}."
        )
    },
    es: {
        required: "Este campo es obligatorio.",
        remote: "Por favor, rellena este campo.",
        email: "Por favor, escribe una dirección de correo válida",
        url: "Por favor, escribe una URL válida.",
        date: "Por favor, escribe una fecha válida.",
        dateISO: "Por favor, escribe una fecha (ISO) válida.",
        number: "Por favor, escribe un número entero válido.",
        digits: "Por favor, escribe sólo dígitos.",
        creditcard: "Por favor, escribe un número de tarjeta válido.",
        equalTo: "Por favor, escribe el mismo valor de nuevo.",
        accept: "Por favor, escribe un valor con una extensión aceptada.",
        maxlength: jQuery.validator.format(
            "Por favor, no escribas más de {0} caracteres."
        ),
        minlength: jQuery.validator.format(
            "Por favor, no escribas menos de {0} caracteres."
        ),
        rangelength: jQuery.validator.format(
            "Por favor, escribe un valor entre {0} y {1} caracteres."
        ),
        range: jQuery.validator.format(
            "Por favor, escribe un valor entre {0} y {1}."
        ),
        max: jQuery.validator.format(
            "Por favor, escribe un valor menor o igual a {0}."
        ),
        min: jQuery.validator.format(
            "Por favor, escribe un valor mayor o igual a {0}."
        )
    }
}

// jQuery.extend(jQuery.validator.messages, languageValidator === 'en' ?  message.en : message.es);
jQuery.extend(jQuery.validator.messages, 'es' === 'en' ?  message.en : message.es);
