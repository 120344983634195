class Samples {
    constructor() {
        //Formulario de muestras
        this.formSamplesCreate = $("#form-samples-create");
        this.sampleNameInput = $("#sampleName");
        this.btnCreateSample = $("#btnCreateSample");
        this.btnCancelSample = $("#btnCancelSample");

        //Valores seleccionados para filtro
        this.selectedGenders = [];
        this.checkboxGender = $(".checkGender");

        this.selectedBus = [];
        this.checkboxBu = $(".checkBu");

        this.selectedVps = [];
        this.checkboxVp = $(".checkVp");

        this.selectedBands = [];
        this.checkboxBand = $(".checkBand");
        this.selectedPersonas = [];
        this.checkboxNumPersonas = $(".checkPersonas");

        //Textos para mostrar segun los filtros
        this.textNoSelected = "Nada seleccionado";
        this.textSelectedGendersSpan = $(".text-selected-genders");
        this.textSelectedBusSpan = $(".text-selected-bus");
        this.textSelectedVpSpan = $(".text-selected-vps");
        this.textSelectedBandSpan = $(".text-selected-bands");
        this.textSelectedPersonasSpan = $(".text-selected-personas");

        //Tiempo de filtro
        this.timeFilter;

        //Resultado filtro
        this.textResultFilterDiv = $(".text-samples-result-filter");

        //Modal de cargando
        this.modalLoading = $("#loadingModal");

        //Modal crtierios
        this.criteriaModal = $("#demographicCriteriaModal");
        this.criteriaBtnEye = $(".btnEye");

        //Elimincacion de muestras
        this.btnDeleteSamples = $("#btnDeleteSamples");
        this.checkSamples = $(".checkSamples");
    }

    showDataByConsole() {
        console.log("bu:", this.selectedBus);
        console.log("band:", this.selectedBands);
        console.log("vp:", this.selectedVps);
        console.log("gender:", this.selectedGenders);
        console.log("name:", this.sampleNameInput.val());
        console.log("# Personas:", this.selectedPersonas);
    }

    changeCheckBoxFilter(checkbox, type) {
        this.disableBtnCreateSample();
        let valuesArr = [];
        checkbox.each(function () {
            if ($(this).is(":checked")) {
                valuesArr.push($(this).val());
            }
        });
        this.assignCheckValuesByType(type, valuesArr);
        if (this.timeFilter) {
            clearTimeout(this.timeFilter);
        }
        this.textResultFilterDiv.removeClass("d-block").addClass("d-none");
        this.timeFilter = setTimeout(() => {
            this.filterUsersByOpcSelected();
        }, 1000);
    }
    changeInputFilter(checkbox, type) {
        this.disableBtnCreateSample();
        this.assignCheckValuesByType(type, Number(checkbox)?Number(checkbox):null);
        if (this.timeFilter) {
            clearTimeout(this.timeFilter);
        }
        this.textResultFilterDiv.removeClass("d-block").addClass("d-none");
        this.timeFilter = setTimeout(() => {
            this.filterUsersByOpcSelected();
        }, 1000);
    }

    assignCheckValuesByType(type, valuesArr) {
        let self = this;
        switch (type) {
            case "gender":
                self.selectedGenders = valuesArr;
                self.printSelectedByType(
                    self.selectedGenders,
                    self.textSelectedGendersSpan
                );
                break;
            case "bu":
                self.selectedBus = valuesArr;
                self.printSelectedByType(
                    self.selectedBus,
                    self.textSelectedBusSpan
                );
                break;
            case "vp":
                self.selectedVps = valuesArr;
                self.printSelectedByType(
                    self.selectedVps,
                    self.textSelectedVpSpan
                );
                break;
                case "band":
                    self.selectedBands = valuesArr;
                    self.printSelectedByType(
                        self.selectedBands,
                        self.textSelectedBandSpan
                    );
                    break;
                    case "npersona":
                self.selectedPersonas = valuesArr;
                self.printSelectedByType(
                    self.selectedPersonas,
                    self.textSelectedPersonasSpan
                );
                break;

            default:
                break;
        }
    }

    printSelectedByType(selectedValues, spanElement) {
        let textValues =
            selectedValues.length > 0
                ? selectedValues.toString()
                : this.textNoSelected;

        spanElement.text(textValues);
    }

    filterUsersByOpcSelected() {
        let objectValues = {
            bu: this.selectedBus.length ? this.selectedBus : null,
            vp: this.selectedVps.length ? this.selectedVps : null,
            band: this.selectedBands.length ? this.selectedBands : null,
            gender: this.selectedGenders.length ? this.selectedGenders : null,
            count:  Number(this.selectedPersonas) ? Number(this.selectedPersonas) : null,
        };
        let objectToString = JSON.stringify(objectValues);
        let encoded = encodeURI(objectToString);
        let self = this;
        $.ajax({
            method: "GET",
            url: "/happindex/api/employees/get-count-build?filters=" + encoded,
            beforeSend: function () {
                self.disableBtnCreateSample();
            },
            success: function (data) {

                self.enableBtnCreateSample();
                self.textResultFilterDiv
                    .removeClass("d-none")
                    .addClass("d-block");
                if (data.data > 0) {
                    self.textResultFilterDiv
                        .find("p")
                        .text(`Muestra total: ${data.data} personas`);
                } else {
                    self.textResultFilterDiv
                        .find("p")
                        .text(
                            `No se encontraron personas para los criterios demográficos seleccionados`
                        );
                }
            },
            error: function (error) {
                console.error("Error: ", error);
                self.textResultFilterDiv
                    .removeClass("d-none")
                    .addClass("d-block");
                self.textResultFilterDiv
                    .find("p")
                    .text(`Ha ocurrido un error...`);
            },
        });
    }

    disableBtnCreateSample() {
        this.btnCreateSample
            .attr("disable", true)
            .removeClass("btnForm--orange")
            .addClass("btnForm--gray");
    }

    enableBtnCreateSample() {
        this.btnCreateSample
            .attr("disable", false)
            .removeClass("btnForm--gray")
            .addClass("btnForm--orange");
    }

    clearFormSample() {
        this.formSamplesCreate.trigger("reset");

        this.selectedBands = [];
        this.selectedBus = [];
        this.selectedVps = [];
        this.selectedGenders = [];
        this.sampleNameInput.val("");

        this.assignCheckValuesByType("gender", []);
        this.assignCheckValuesByType("bu", []);
        this.assignCheckValuesByType("vp", []);
        this.assignCheckValuesByType("band", []);
        this.assignCheckValuesByType("npersona", []);

        this.textResultFilterDiv.removeClass("d-block").addClass("d-none");
    }

    showLoadingModal() {
        this.modalLoading.modal("show");
    }

    saveSample() {
        let self = this;
        let dataToSend = {
            bu: this.selectedBus.length ? this.selectedBus : null,
            band: this.selectedBands.length ? this.selectedBands : null,
            vp: this.selectedVps.length ? this.selectedVps : null,
            gender: this.selectedGenders.length ? this.selectedGenders : null,
            name: this.sampleNameInput.val(),
            count: this.selectedPersonas ? Number(this.selectedPersonas) : null,
        };

        if (!self.btnCreateSample.hasClass("btnForm--gray")) {
            $.ajax({
                method: "POST",
                url: "/happindex/api/samples/build",
                data: dataToSend,
                beforeSend: function () {
                    self.disableBtnCreateSample();
                    self.showLoadingModal();
                },
                success: function (data) {
                    self.enableBtnCreateSample();
                    self.clearFormSample();
                    location.reload();
                },
                error: function (error) {
                    console.error("Error: ", error);
                },
            });
        }
    }

    showCriteriaModal(data) {
        let content = "";
        if (data === null) {
            content += `
            <h3 class="modal-1__title">Criterios demográficos seleccionados</h3>
            <div class="modal-1__content">
                <p class="modal-1__content--gray">
                    No se encuentran criterios demográficos ya que la muestra fue creada por el usuario al subir un archivo
                </p>
                <button type="button" class="btnForm btnForm--orange px-5" data-dismiss="modal">Ok</button>
            </div>
            `;
        } else {
            let { gender_criteria, bu_criteria, vp_criteria, band_criteria } =
                data;
            content += `
            <h3 class="modal-1__title">Criterios demográficos seleccionados</h3>
            <div class="modal-1__content">
                <p class="modal-1__content--orange">Género</p>
                <p class="modal-1__content--gray">${gender_criteria.join(
                    ", "
                )}</p>
            </div>
            <div class="modal-1__content">
                <p class="modal-1__content--orange">BU</p>
                <p class="modal-1__content--gray">${bu_criteria.join(", ")}</p>
            </div>
            <div class="modal-1__content">
                <p class="modal-1__content--orange">Área</p>
                <p class="modal-1__content--gray">${vp_criteria.join(", ")}</p>
            </div>
            <div class="modal-1__content">
                <p class="modal-1__content--orange">Banda</p>
                <p class="modal-1__content--gray">${band_criteria.join(
                    ", "
                )}</p>
            </div>
            `;
        }

        this.criteriaModal.find(".modal-body").html(content);
        this.criteriaModal.modal("show");
    }

    deleteSamples() {
        let self = this;
        let samplesToDelete = [];
        $(".checkSamples:checked").each(function (i) {
            samplesToDelete[i] = $(this).val();
        });
        if (samplesToDelete.length) {
            self.showLoadingModal();
            samplesToDelete.map((id) => {
                $.ajax({
                    method: "DELETE",
                    url: "/happindex/api/samples/" + id,
                    error: function (error) {
                        console.error("Error: ", error);
                    },
                });
            });

            setTimeout(() => {
                location.reload();
            }, 1500);
        }
    }
}

if ($("#form-samples-create").length) {
    let samples = new Samples();

    samples.checkboxGender.on("change", function () {
        samples.changeCheckBoxFilter(samples.checkboxGender, "gender");
    });

    samples.checkboxBu.on("change", function () {
        samples.changeCheckBoxFilter(samples.checkboxBu, "bu");
    });

    samples.checkboxVp.on("change", function () {
        samples.changeCheckBoxFilter(samples.checkboxVp, "vp");
    });

    samples.checkboxBand.on("change", function () {
        samples.changeCheckBoxFilter(samples.checkboxBand, "band");
    });
    samples.checkboxNumPersonas.on("change", function () {
        samples.changeInputFilter(samples.checkboxNumPersonas.val(), "npersona");
    });

    samples.formSamplesCreate.on("submit", function (e) {
        e.preventDefault();
        samples.saveSample();
    });

    samples.btnCancelSample.on("click", function () {
        samples.clearFormSample();
    });

    samples.criteriaBtnEye.on("click", function () {
        let criteria = $(this).data("criteria");
        samples.showCriteriaModal(criteria);
    });

    samples.btnDeleteSamples.on("click", function () {
        samples.deleteSamples();
    });
}
