const { default: SanitizeJS } = require("./sanitize");
const { default: SanitizeCustom } = require("./sanitizeCustom");

class Pulse {
    constructor(sanitize) {
        this.sanitize = sanitize;
        this.globalTimeOut = null;
        this.tablePulseIconSearch = $("#tablePulseIconSearch");
        this.tablePulseInputSearch = $("#tablePulseInputSearch");
        this.pulseDataTable = null;
        this.pulseTable = $("#pulseDataTable");
        this.pulseTable2 = $("#pulseDataTable2");
        this.chksBu = $(".chk-bu");
        this.chksArea = $(".chk-area");
        this.chksOrder = $(".chk-order");
        this.chksStatus = $(".chk-status");
        this.chksResult = $(".chk-result");
        this.btnTableFilter = $("#btnTableFilter");
        this.contentTableFilter = $("#tableFilterContent");
        //Grafica por bu
        this.graphTimeOut;
        this.graphBu = $(".graph-bu");
        this.textReportLabel = $(".text-report-label");
        this.textReportSample = $("#text-report-sample");
        this.textReportPercentage = $("#text-report-percentage");
        this.textReportTotal = $("#text-report-total");
        this.textReportStatusGreen = $(".text-report-status-maz-green");
        this.textReportStatusYellow = $(".text-report-status-maz-yellow");
        this.textReportStatusRed = $(".text-report-status-maz-red");
        //Grafica por area
        this.graphArea = $(".graph-area");
        //Pulso
        this.pulseId = this.sanitize.escape($("#idPulse").val());
        this.busSelected = [];
        this.areasSelected = [];
        this.orderSelected = [];
        //Indiviudal
        this.statusSelected = [];
        this.resultSelected = [];
        //Envio de recordatorio
        this.dataTableCheckboxes = ".pulse-checkbox";
        this.usersSelected = [];
        this.inputPollLinks = "#input_poll_links";
        this.btnAirPlane = ".btn-airplane";
        //Download report
        this.buttonDownloadReport = $("#download_report");
        this.downloadButton = $("#download-individual");
    }

    init() {
        if (this.pulseTable.length) {
            this.initialDataTable();
        }
        if (this.graphBu.length) {
            if (this.graphTimeOut) {
                clearTimeout(graphTimeOut);
            }
            this.graphTimeOut = setTimeout(() => {
                this.initGrpahBu();
            }, 500);
        }
        if (this.pulseTable2.length) {
            this.initialDataTable2();
            $("#download-individual").hide(); 
        }
    }

    replaceSpecialCharacters(text) {
        return text.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "-");
    }

    searchInDataTable() {
        let self = this;
        if (self.globalTimeOut !== null) {
            clearTimeout(self.globalTimeOut);
        }
        self.globalTimeOut = setTimeout(() => {
            self.pulseDataTable.search(self.tablePulseInputSearch.val()).draw();
        }, 500);
    }

    initialDataTable() {
        let self = this;
        // order = document.getElementById("orderBy")
        // var valorSeleccionado = order.value;
        // alert(valorSeleccionado)
        this.pulseDataTable = this.pulseTable.DataTable({
            ajax: function (data, callback, settings) {
                $.get(
                    `/happindex/api/pulses/${self.pulseId}/get-employee-progress`,
                    {
                        page: data.start / data.length + 1,
                        search: data.search.value,
                        filters: {
                            bus: self.busSelected,
                            areas: self.areasSelected,
                            status: self.statusSelected,
                            status_report: self.resultSelected,
                            order: self.orderSelected,
                        },
                    },
                    function (json) {
                        callback({
                            recordsTotal: json.total,
                            recordsFiltered: json.total,
                            data: json.data,
                        });
                    }
                );
            },
            columns: [
                { data: "sharp_id" },
                { data: "name" },
                { data: "related_name" },
                { data: "position" },
                { data: "bu" },
                { data: "area" },
                { data: "status" },
                { data: "owner_of_the_sample" },
                {
                    data: "report",
                    render(data, type, row) {
                        return data === null
                            ? "-"
                            : `<a href="${data}" target="_blank">${
                                  lang_messages.available
                              }</a> <p class="tw-text-xs tw-m-0 tw-text-gray-600"><span>${
                                  row.report_sent
                                      ? lang_messages.sent_to
                                      : lang_messages.not_sent
                              }</span></p>`;
                    },
                },
                {
                    data: "poll_link_id",
                    render(data, type, row) {
                        return `<input type="checkbox" class="tw-form-checkbox-maz-light-green-500 tw-w-4 tw-h-4 tw-mt-1 pulse-checkbox" value="${data}" />`;
                    },
                },
            ],
            searching: true,
            lengthChange: false,
            info: false,
            responsive: true,
            processing: true,
            serverSide: true,
        });

        this.pulseDataTable.draw();

        this.tablePulseInputSearch.on("keyup", () => {
            self.searchInDataTable();
        });
    }

    initialDataTable2() {
        let self = this;
        this.pulseDataTable = this.pulseTable2.DataTable({
            ajax: function (data, callback, settings) {
                $.get(
                    `/happindex/api/individual-response-rate/${self.pulseId}`,
                    {
                        page: data.start / data.length + 1,
                        search: data.search.value,
                        filters: {
                            status: self.statusSelected,
                        },
                    },
                    function (json) {
                        callback({
                            recordsTotal: json.total,
                            recordsFiltered: json.total,
                            data: json.data,
                        });
                    }
                );
            },
            columns: [
                { data: "related" },
                { data: "name" },
                { data: "total" },
                {
                    data: "completed",
                    render(data, type, row) {
                        return `${
                            row.completed !== 0
                                ? ((row.completed * 100) / row.total).toFixed(0)
                                : 0
                        }%`;
                    },
                },
                { 
                    data: "donwload",
                    render(data, type, row) {
                        return `<a href="/happindex/api/individual-response-report/${self.pulseId}/${row.related}" target="_blank">Descargar</a>`;
                    },
                },
                {
                    data: "arrow",
                    render(data, type, row) {
                        return `<i class="fa-solid fa-chevron-right details-control tw-transform tw-rotate-0" data-related="${row.related}"></i>`;
                    },
                },
            ],
            searching: true,
            lengthChange: false,
            info: false,
            responsive: true,
            processing: true,
            serverSide: true,
            initComplete: function () {
                // Verificar si la tabla tiene elementos
                if (self.pulseDataTable.rows().count() > 0) {
                    $("#download-individual").show();  // Mostrar el botón si hay elementos en la tabla
                } else {
                    $("#download-individual").hide();  // Ocultar el botón si no hay elementos en la tabla
                }
            }
        });
         // Ocultar el botón si no hay elementos en la tabla
        this.pulseDataTable.draw();
        this.tablePulseInputSearch.on("keyup", () => {
            self.searchInDataTable();
        });
    }

    showInputSearch() {
        this.tablePulseInputSearch.show().trigger("focus");
        this.tablePulseIconSearch
            .removeClass("tw-relative")
            .addClass("tw-absolute tw-top-1");
    }

    hideInputSearch() {
        this.tablePulseInputSearch.hide();
        this.tablePulseIconSearch
            .removeClass("tw-absolute tw-top-1")
            .addClass("tw-relative");
    }

    filterTableByBu() {
        let searchTerms = [];
        $.each(this.chksBu, function (i, elem) {
            if ($(elem).prop("checked")) {
                searchTerms.push($(this).val());
            }
        });

        this.busSelected = searchTerms;
        this.pulseDataTable.draw();
    }

    filterTableByArea() {
        let searchTerms = [];
        $.each(this.chksArea, function (i, elem) {
            if ($(elem).prop("checked")) {
                searchTerms.push($(this).val());
            }
        });

        this.areasSelected = searchTerms;
        this.pulseDataTable.draw();
    }

    filterTableByOrder() {
        let orders = [];
        $.each(this.chksOrder, function (i, elem) {
            if ($(elem).prop("checked")) {
                orders.push($(this).val());
            }
        });

        this.orderSelected = orders;
        this.pulseDataTable.draw();
    }

    filterTableByStatus(element) {
        let searchTerms = [];
        $.each(this.chksStatus, function (i, elem) {
            if ($(elem).prop("checked")) {
                searchTerms.push($(this).val());
            }
        });

        this.statusSelected = searchTerms;
        let existsClass = this.sanitize.escape($(element).hasClass("chk-1"));
        if (existsClass) {
            this.pulseDataTable.draw();
        }
    }
   
        filterTableByResults(element) {
        console.log("RESULT CHECK")

        let searchTerms = [];
        $.each(this.chksResult, function (i, elem) {
            if ($(elem).prop("checked")) {
                searchTerms.push($(this).val());
            }
        });

        this.resultSelected = searchTerms;
        let existsClass = this.sanitize.escape($(element).hasClass("chk-result"));
        console.log("Resultado seleccionado:  ", this.resultSelected);
        if (existsClass) {
            this.pulseDataTable.draw();
        }
    }
    toggleFilterContent() {
        this.contentTableFilter.toggle();
        this.hideInputSearch();
    }

    getDataTableDetails(element, related) {
        console.log("A1")
        let self = this;
        let existsClass = this.sanitize.escape($(element).hasClass("tw-transform tw-rotate-0"));
        element = this.sanitize.escapeHtml(element);

        if (existsClass) {
            $(element)
                .removeClass("tw-transform tw-rotate-0")
                .addClass("tw-transform tw-rotate-90");
            self.getRelatedDataFromDataTable2(element, related);
        } else {
            let identifier = this.sanitize.escape("#tr-detail-" + related);
            $(element)
                .removeClass("tw-transform tw-rotate-90")
                .addClass("tw-transform tw-rotate-0");
            $(identifier).remove();
        }
    }

    showDataTableDetails(element, data, related) {
        console.log("SUCCESS..!!",data)

        let tr = $(element).closest("tr");
        let icon = $(tr).find(".details-control");

        if (data) {
            let trContent = "";
            $(icon)
                .addClass("fa-solid fa-chevron-right")
                .removeClass("fa fa-spinner tw-animate-spin");
            let dataTr = data
                .map((element) => {
                    let elementStatus =
                        element.status !== "Completed" &&
                        element.status !== "Completado"
                            ? `<input type="checkbox" class="chkPollLinks pulse-checkbox" name="chkPollLinks[]" value="${element.poll_link_id}" />`
                            : '<i class="fa-solid fa-check"></i>';
                    return `
                    <tr>
                        <td>${element.sharp_id}</td>
                        <td>${element.name}</td>
                        <td>${element.status}</td>
                        <td>${elementStatus}</td>
                    </tr>
                    `;
                })
                .join("");
            trContent = $(
                `<tr id="tr-detail-${related}">
                    <td colspan='5'>
                        <div class="tw-px-4">
                        <div class="tw-border tw-border-solid tw-border-gray-400 tw-rounded-xl tw-h-48 tw-overflow-y-auto">
                            <table class="tw-w-full">
                            <thead>
                                <tr>
                                <th class="tw-rounded-tl-xl">Shrap ID</th>
                                <th>Nombre del reporte</th>
                                <th>Status</th>
                                <th class="tw-rounded-tr-xl"></th>
                                </tr>
                            </thead>
                            <tbody>
                                ${dataTr}
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </td>
                    </tr>`
            );
            trContent.insertAfter(tr);
        } else {
            $(icon)
                .removeClass("fa-solid fa-chevron-right")
                .addClass("fa fa-spinner tw-animate-spin");
        }
    }

    getRelatedDataFromDataTable2(element, related) {
        let self = this;
        $.ajax({
            type: "GET",
            url: `/happindex/api/individual-response-rate/${self.pulseId}/${related}`,
            data: {
                filters: { status: self.statusSelected },
            },
            beforeSend: function () {
                self.showDataTableDetails(element, null, related);
            },
            success: function (data) {
                self.showDataTableDetails(element, data, related);
            },
            error(error) {
                console.error("Error: ", error);
            },
        });
      
    }

    initGrpahBu() {
        this.graphBu.first().trigger("click");
    }

    changeDataByBu(element) {
        const data = this.sanitize.escapeObject($(element).data("info"));

        this.textReportLabel.text(data.label);
        this.textReportSample.text(data.response_rate.sample);
        this.textReportPercentage.text(data.response_rate.percentage);
        this.textReportTotal.text(data.response_rate.total);
        this.textReportStatusGreen.text(
            `${data.completed.percentage}% - ${data.completed.total} personas`
        );
        this.textReportStatusYellow.text(
            `${data.in_progress.percentage}% - ${data.in_progress.total} personas`
        );
        this.textReportStatusRed.text(
            `${data.not_open.percentage}% - ${data.not_open.total} personas`
        );

        this.graphBu.removeClass("tw-bg-maz-gray-blue-500");

        let applyClass = this.sanitize.escape("tw-bg-maz-gray-blue-500");
        $(element).addClass(applyClass);

        this.checkedByBu(data.label);
    }

    checkedByBu(label) {
        label = this.replaceSpecialCharacters(label);

        this.chksBu.prop("checked", false);
        this.busSelected = [];

        $(`.chk-bu[type=checkbox][data-id=${label}]`).prop("checked", true);
        this.busSelected.push(label);

        this.filterTableByBu();
    }

    changeDataByArea(element) {
        const data = this.sanitize.escapeObject($(element).data("info"));
        this.textReportLabel.text(data.label);

        this.graphArea.removeClass("tw-bg-maz-gray-blue-500");

        let applyClass = this.sanitize.escape("tw-bg-maz-gray-blue-500");
        $(element).addClass(applyClass);

        this.checkedByArea(data.label);
    }

    changeDataByOrder(element) {
        const data = this.sanitize.escapeObject($(element).data("info"));
        this.textReportLabel.text(data.label);

        this.graphArea.removeClass("tw-bg-maz-gray-blue-500");

        let applyClass = this.sanitize.escape("tw-bg-maz-gray-blue-500");
        $(element).addClass(applyClass);

        this.checkedByOrder(data.label);
    }

    checkedByArea(label) {
        label = this.replaceSpecialCharacters(label);

        this.chksArea.prop("checked", false);
        this.areasSelected = [];

        $(`.chk-area[type=checkbox][data-id=${label}]`).prop("checked", true);
        this.areasSelected.push(label);

        this.filterTableByArea();
    }

    checkedByOrder(label) {
        label = this.replaceSpecialCharacters(label);

        this.chksOrder.prop("checked", false);
        this.orderSelected = [];

        $(`.chk-area[type=checkbox][data-id=${label}]`).prop("checked", true);
        this.orderSelected.push(label);

        this.filterTableByOrder();
    }

    selectUsersByCheck(userId, checked) {
        if (checked) {
            if (!this.usersSelected.includes(userId)) {
                this.usersSelected.push(userId);
            }
        } else {
            this.usersSelected.splice(this.usersSelected.indexOf(userId), 1);
        }
        $(this.inputPollLinks).val(this.usersSelected);
    }

    toggleAirPlaneBtn() {
        if (this.usersSelected.length > 0) {
            $(this.btnAirPlane).fadeIn();
        } else {
            $(this.btnAirPlane).fadeOut();
        }
    }

    downloadReport() {
        const sanitize = new SanitizeJS();
        const sanitizedSearch = sanitize.sanitize(
            this.tablePulseInputSearch.val()
        );

        let params = new URLSearchParams({
            search: sanitizedSearch,
            filters: JSON.stringify({
                bus: this.busSelected,
                areas: this.areasSelected,
            }),
        });

        let href = encodeURIComponent(this.buttonDownloadReport.attr("href") + "?" + params.toString());
        href = decodeURIComponent(sanitize.sanitize( href ));

        this.buttonDownloadReport.attr("href", href);
    }
}

if ($("#contianer-pulse-table").length) {
    const sanitizeCustom = new SanitizeCustom();
    let pulse = new Pulse(sanitizeCustom);


    pulse.init();

    pulse.tablePulseIconSearch.on("click", () => {
        pulse.showInputSearch();
    });

    pulse.tablePulseInputSearch.on("focusout", () => {
        pulse.hideInputSearch();
    });

    pulse.chksBu.on("change", () => {
        pulse.filterTableByBu();
    });

    pulse.chksArea.on("change", () => {
        pulse.filterTableByArea();
    });

    pulse.chksOrder.on("change", () => {
        pulse.filterTableByOrder();
    });

    pulse.chksStatus.on("change", function () {
        pulse.filterTableByStatus($(this));
    });
    pulse.chksResult.on("change", function () {
        pulse.filterTableByResults($(this));
    });

    pulse.btnTableFilter.on("click", () => {
        pulse.toggleFilterContent();
    });

    //Grafica bu
    pulse.graphBu.on("click", function () {
        pulse.changeDataByBu($(this));
    });

    //Grafica area
    pulse.graphArea.on("click", function () {
        pulse.changeDataByArea($(this));
    });

    //Check para el data table
    $(document).on("change", pulse.dataTableCheckboxes, function () {
        const userId = sanitizeCustom.escape($(this).val());
        let checked = false;
        if (this.checked) {
            checked = true;
        } else {
            checked = false;
        }
        pulse.selectUsersByCheck(userId, checked);
        pulse.toggleAirPlaneBtn();
    });

    //Ver detalle data table
    $(pulse.pulseTable2).on("click", "tr td i.details-control", function () {
        pulse.getDataTableDetails($(this), $(this).data("related"));
    });

    //Controlar boton de recoradorio
    if ($(pulse.btnAirPlane).length) {
        $(pulse.btnAirPlane).fadeOut("fast");
    }

    //Download report
    if (pulse.buttonDownloadReport.length) {
        pulse.buttonDownloadReport.on("click", function () {
            pulse.downloadReport();
        });
    }
}
